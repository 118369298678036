.Icon {
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  padding: 5px;
  user-select: none;
  width: 90px;
}

.Icon-img {
  height: 20px;
  margin-bottom: 10px;
  width: 20px;
}

.Icon-off {
  background-color: lightgray;
}

.Icon-on {
  background-color: black;
}

.Icon-text {
  font-weight: bold;
  font-size: 12px;
}

.Icon-text-off {
  color: black;
}

.Icon-text-on {
  color: white;
}
