.Window {
  background-color: beige;
  border-radius: 5px;
}

.Window-body {
  border: 1px solid brown;
  height: 400px;
  margin: 0 10px 10px 10px;
  padding: 10px;
  width: 500px;
}

.Window-header {
  display: flex;
  flex-direction: row;
  padding: 5px 10px;
  user-select: none;
}

.Window-header-icon {
  cursor: pointer;
  flex-grow: 0;
}

.Window-header-text {
  text-align: center;
  flex-grow: 1;
  font-weight: bold;
}
